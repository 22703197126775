/**
 * Contextual Layers Array: The application groups layers based on the Mapbox
 * style prefixes defined by the IDs listed below. Grouping these layers ensures
 * the proper display of their respective labels.
 */
const contextualLayers = [
  {
    id: 'cpal-dallas-city-tracts',
    label: 'Census Tracts',
  },
  {
    id: 'cpal-dallas-council',
    label: 'City Council Districts',
  },
  {
    id: 'cpal-dallas-city-limits',
    label: 'City Limits',
  },
  {
    id: 'cpal-service-area-divisions',
    label: 'Service Area Divisions',
  },
];

// module exports is required to be able to load from gulpfile.
module.exports = {
  default: {
    environment: 'production',
    blightConfidenceThreshold: 0.5,
    baseUrl: process.env.BASE_URL || 'http://localhost:9000',
    apiEndpoint: 'https://d2pewvjvc383bn.cloudfront.net',
    mapboxStyle: 'mapbox://styles/devseed/cllmggg7u02ik01mf9sew2obi',
    mapboxAccessToken:
      'pk.eyJ1IjoiZGV2c2VlZCIsImEiOiJnUi1mbkVvIn0.018aLhX0Mb0tdtaT2QNe2Q',
    mapillaryAccessToken:
      'MLY|5274865055937569|b1995f5fc5472df4e5cbafee5d8aa4b9',
    contextualLayers,
  },
};
