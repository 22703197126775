import React, { useState } from 'react';
import T from 'prop-types';
import styled, { css, createGlobalStyle } from 'styled-components';
import { Link, NavLink } from 'react-router-dom';

import { glsp, themeVal, rgba, media } from '@devseed-ui/theme-provider';
import { createHeadingStyles } from '@devseed-ui/typography';
import { Button } from '@devseed-ui/button';
import { reveal } from '@devseed-ui/animation';

import filterComponentProps from '../../utils/filter-component-props.js';

import CPALLogo from '../../../media/layout/CPAL_Logo_OneColor.png';
import {
  CollecticonHamburgerMenu,
  CollecticonXmarkSmall,
} from '@devseed-ui/collecticons';

export const HEADER_HEIGHT = '4.5rem';

const PageHeaderSelf = styled.header`
  position: sticky;
  top: 0;
  z-index: 2000;
  background: ${themeVal('color.surface')};
  border-bottom: 1px solid ${themeVal('color.base-200')};
  animation: ${reveal} 0.32s ease 0s 1;
`;
const PageHeaderInner = styled.div`
  align-items: center;
  display: grid;
  grid-gap: ${glsp(2)};
  grid-template-columns: max-content 1fr;
  margin: 0 auto;
  max-width: ${({ isMapCentric }) => !isMapCentric && themeVal('layout.max')};
  padding: ${({ isMapCentric }) => (isMapCentric ? '1rem' : glsp())};
  position: relative;
  z-index: 30;
  /* Animation */
  animation: ${reveal} 0.32s ease 0s 1;
  &::before {
    position: absolute;
    z-index: 40;
    top: 0;
    left: 0;
    width: 100%;
    height: 4.5rem;
    content: '';
    background: linear-gradient(
      0deg,
      ${rgba(themeVal('color.surface'), 0)} 75%,
      ${rgba(themeVal('color.surface'), 1)} 100%
    );
    ${media.mediumUp`
      display: none;
    `}
  }
  ${media.smallUp`
    padding: ${({ isMapCentric }) => (isMapCentric ? '1rem 1.5rem' : glsp())};
  `}
`;

const Brand = styled.div`
  z-index: 50;
  a,
  a:visited {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: ${glsp(0.5)};
    text-decoration: none;
  }
  img {
    height: 1.75rem;
    display: none;
    ${media.smallUp`
      display: inline;
    `}
  }
  span {
    ${createHeadingStyles({ size: 'small' })}
    line-height: 1.1;
    display: block;
    font-size: 0.675rem;
    letter-spacing: 0.075px;
    text-transform: uppercase;
    font-variation-settings: 'wdth' 80, 'wght' 400;
    ${media.smallUp`
      width: 7rem;
      margin-top: -0.05rem;
      padding-left: ${glsp(0.5)};
      border-left: 1px solid ${themeVal('color.primary')};
    `}
    > span {
      font-variation-settings: 'wdth' 80, 'wght' 740;
      ${media.smallUp`
      display: none;
    `}
    }
  }
`;

const GlobalNav = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  z-index: 20;
  display: flex;
  flex-flow: column nowrap;
  padding-top: ${glsp(5)};
  padding-bottom: ${glsp()};
  padding: ${glsp(5)} ${glsp()} ${glsp()};
  overflow: auto;
  pointer-events: auto;
  background: ${themeVal('color.surface')};
  transform: translate(0, -100%);
  margin: 0;
  transition: all 0.4s ease-in-out 0s;
  will-change: transform;
  box-shadow: ${themeVal('boxShadow.elevationD')};
  ${({ revealed }) =>
    revealed &&
    css`
      transform: translate(0, 0);
    `}
  ${media.smallUp`
    padding-top: ${glsp(6)};
    padding-bottom: ${glsp(6)};
  `}
  ${media.mediumUp`
    margin-left: auto;
    position: static;
    flex-flow: row nowrap;
    padding: 0;
    overflow: visible;
    transform: translate(0, 0);
    justify-content: space-between;
    box-shadow: none;
  `}
`;

const GlobalMenu = styled.ul`
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: ${glsp(0.5)};
  margin: 0;
  list-style: none;
  ${media.mediumUp`
    flex-flow: row nowrap;
  `}
`;

const PageNavToggleWrapper = styled.div`
  position: relative;
  z-index: 50;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  ${media.mediumUp`
    display: none;
  `}
`;
const PageNavToggle = styled(Button)``;

const PageNavGlobalStyle = createGlobalStyle`
  body {
    ${({ isActive }) =>
      isActive &&
      css`
        overflow: hidden;
      `}
  }
`;
const GlobalMenuLink = styled(Button)`
  /* styled-component */
`;

// See documentation of filterComponentProp as to why this is
const propsToFilter = [
  'variation',
  'size',
  'hideText',
  'active',
  'visuallyDisabled',
];
const StyledNavLink = filterComponentProps(NavLink, propsToFilter);

function PageHeader({ isMapCentric }) {
  const [navRevealed, setNavRevealed] = useState(false);
  return (
    <PageHeaderSelf>
      <PageHeaderInner isMapCentric={isMapCentric}>
        <Brand>
          <Link to='/'>
            <img src={CPALLogo} alt='CPAL Logo' />
            <span>
              <span>CPAL</span>Urban Blight Tracker
            </span>
          </Link>
        </Brand>
        <PageNavGlobalStyle isActive={navRevealed} />
        <PageNavToggleWrapper>
          <PageNavToggle
            hideText
            type='text'
            title='Reveal/hide menu'
            onClick={() => setNavRevealed((v) => !v)}
          >
            {navRevealed ? (
              <CollecticonXmarkSmall meaningful title='Hide Menu' />
            ) : (
              <CollecticonHamburgerMenu meaningful title='Reveal menu' />
            )}
          </PageNavToggle>
        </PageNavToggleWrapper>
        <GlobalNav as='nav' role='navigation' revealed={navRevealed}>
          <GlobalMenu>
            <li>
              <GlobalMenuLink
                forwardedAs={StyledNavLink}
                to='/'
                end
                variation='base-text'
              >
                Home
              </GlobalMenuLink>
            </li>
            <li>
              <GlobalMenuLink
                forwardedAs={StyledNavLink}
                to='/explore'
                end
                variation='base-text'
              >
                Explore
              </GlobalMenuLink>
            </li>
            <li>
              <GlobalMenuLink
                forwardedAs={StyledNavLink}
                to='/about'
                end
                variation='base-text'
              >
                About
              </GlobalMenuLink>
            </li>
          </GlobalMenu>
        </GlobalNav>
      </PageHeaderInner>
    </PageHeaderSelf>
  );
}

PageHeader.propTypes = {
  isMapCentric: T.bool,
};

export default PageHeader;
