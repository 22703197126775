import styled, { css } from 'styled-components';

import {
  visuallyHidden,
  themeVal,
  glsp,
  rgba,
  media,
} from '@devseed-ui/theme-provider';
import { Heading } from '@devseed-ui/typography';

export const Inpage = styled.article`
  display: grid;
  height: 100%;
  grid-template-rows: auto 1fr;

  /**
   * Make Inpage map-centric
   *
   * Vizually hides inpageHeader and sets the grid layout to a single row.
   * The latter is needed so that inpageBody can be displayed in full height.
   */

  ${({ isMapCentric }) =>
    isMapCentric &&
    css`
      grid-template-rows: 1fr;
      ${InpageHeader} {
        ${visuallyHidden()}
      }
    `}
`;

export const InpageHeader = styled.header`
  /* Visually hidden */
  ${({ isHidden }) =>
    isHidden &&
    css`
      ${visuallyHidden()}
    `}
`;

export const InpageHeaderInner = styled.div`
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: space-between;
  padding: ${glsp(2)} ${glsp()};
  margin: 0 auto;
  max-width: 100vw;
  ${media.mediumUp`
    max-width: ${themeVal('layout.max')};
    flex-flow: row nowrap;
    align-items: baseline;
  `}
`;

export const InpageHeadline = styled.div`
  display: flex;
  flex-flow: column;
  /* min-width: 100%; */

  > *:last-child {
    margin-bottom: 0;
  }
`;

export const InpageToolbar = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: space-between;
  margin-top: ${glsp(2)};
  > * ~ * {
    margin-top: ${glsp()};
  }
  ${media.mediumUp`
    flex-flow: row nowrap;
    align-items: center;
    padding-left: ${glsp(2)};
    margin-left: auto;
    margin-top: 0;
    > * ~ * {
      margin-left: ${glsp()};
      margin-top: 0;
    }
  `}
`;

export const InpageTitleWrapper = styled.div`
  display: flex;
  min-width: 0;
  flex-flow: column nowrap;
  margin-bottom: ${glsp()};
  ${media.mediumUp`
    align-items: baseline;
    flex-flow: row nowrap;
    & > *:not(:first-child) {
      margin-left: ${glsp()};
    }
  `}
`;

export const InpageTitle = styled(Heading)`
  margin: 0;
  font-size: clamp(1rem, -1rem + 10vw, 3rem);
  line-height: 1;
`;

export const InpageTagline = styled.p`
  order: -1;
  font-size: 0.875rem;
  line-height: 1rem;
  color: ${rgba('#FFFFFF', 0.64)};
`;

export const InpageBody = styled.div`
  background: transparent;
  background: ${themeVal('color.background')};
  ${({ dark }) =>
    dark &&
    css`
      background: ${themeVal('color.primary-800')};
      color: ${themeVal('color.surface')};
    `}
`;

export const InpageBodyInner = styled.div`
  padding: ${glsp(2)} ${glsp()};
  margin: 0 auto;
  max-width: 100vw;
  ${media.mediumUp`
    max-width: ${themeVal('layout.max')};
    padding: ${glsp(4)} ${glsp()};
    `}
`;

export const InpageBodyInnerForm = styled.section`
  max-width: 22rem;
  margin: 0 auto;
  ${Heading} {
    text-align: center;
  }
`;

export const TableInpageHeader = styled(InpageHeader)`
  background: ${themeVal('color.surface')};
  color: ${themeVal('color.base')};
  box-shadow: ${themeVal('boxShadow.elevationA')};
  z-index: 1;
  ${InpageHeaderInner} {
    padding: ${glsp(2)} ${glsp()};
  }
  ${InpageTitle} {
    color: ${themeVal('color.base')};
  }
  ${media.xlargeUp`
    ${InpageHeaderInner} {
      padding: ${glsp(3)} ${glsp()};
    }
  `}
`;
