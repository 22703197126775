import React, { useEffect, Suspense } from 'react';
import { render } from 'react-dom';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import config from './config';
import mapbox from 'mapbox-gl';

import { DevseedUiThemeProvider } from '@devseed-ui/theme-provider';
import theme from './styles/theme';
import GlobalStyles from './styles/global';
import ErrorBoundary from './fatal-error-boundary';

import {
  GlobalLoadingProvider,
  GlobalLoading,
} from '@devseed-ui/global-loading';
import history from './history';
import LayoutRoot, {
  LayoutRootContextProvider,
} from './components/common/layout';

const Home = React.lazy(() => import('./components/home'));
const About = React.lazy(() => import('./components/about'));
const Explore = React.lazy(() => import('./components/explore'));
const UhOh = React.lazy(() => import('./components/uhoh'));

mapbox.accessToken = config.mapboxAccessToken;

// Root component.
function Root() {
  const banner = document.querySelector('#welcome-banner');

  // Hide the welcome banner after timeout
  useEffect(() => {
    if (banner) {
      banner.classList.add('dismissed');
      setTimeout(() => banner.remove(), 500);
    }
  }, [banner]);

  return (
    <ErrorBoundary>
      <BrowserRouter history={history}>
        <LayoutRootContextProvider>
          <DevseedUiThemeProvider theme={theme}>
            <GlobalStyles />
            <GlobalLoadingProvider />
            <Suspense fallback={!banner && <GlobalLoading />}>
              <Routes>
                <Route path='/' element={<LayoutRoot />}>
                  <Route index element={<Home />} />
                  <Route path='about' element={<About />} />
                  <Route
                    exact
                    path='/explore'
                    element={<Navigate to='/explore/region/1' replace />} // Region 1 is hardcoded
                  />
                  <Route
                    exact
                    path='explore/region/:regionId'
                    element={<Explore />}
                  />
                  <Route
                    exact
                    path='explore/region/:regionId/subregion/:subregionId'
                    element={<Explore />}
                  />
                  <Route
                    exact
                    path='explore/region/:regionId/grid/cell/:gridCellId'
                    element={<Explore />}
                  />
                  <Route
                    exact
                    path='explore/region/:regionId/subregion/:subregionId/inference_point/:inferencePointId'
                    element={<Explore />}
                  />
                  <Route
                    exact
                    path='explore/region/:regionId/grid/cell/:gridCellId/inference_point/:inferencePointId'
                    element={<Explore />}
                  />
                  <Route path='*' element={<UhOh />} />
                </Route>
              </Routes>
            </Suspense>
          </DevseedUiThemeProvider>
        </LayoutRootContextProvider>
      </BrowserRouter>
    </ErrorBoundary>
  );
}

// We use the legacy React API in this project because react-helmet is not
// compatible with the new one introduced in React v18.
// See: https://github.com/nfl/react-helmet/issues/669#issuecomment-1134228545
render(<Root />, document.querySelector('#app-container'));
