import { createUITheme } from '@devseed-ui/theme-provider';

export default function themeOverrides() {
  const baseFontFamily =
    '"Inter", ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"';

  return createUITheme({
    color: {
      primary: '#008097',
      secondary: '#EACA2D',
      tertiary: '#ED018C',
    },
    type: {
      base: {
        family: baseFontFamily,
        weight: '400',
        settings: 'normal',
        size: '15px',
      },
      heading: {
        family: baseFontFamily,
        weight: '400',
        settings: '"wdth" 80,"wght" 600',
      },
    },
    button: {
      type: {
        family: baseFontFamily,
        settings: '"wdth" 80,"wght" 500',
      },
    },
    layout: {
      max: '1440px',
      // The gap is defined as a multiplier of the layout.space The elements
      // that use the gap should use it as a parameter for the glsp function
      gap: {
        xsmall: 1,
        small: 1,
        medium: 2,
        large: 2,
        xlarge: 2,
      },
    },
  });
}
