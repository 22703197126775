import React, { createContext, useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import T from 'prop-types';
import styled from 'styled-components';
import { reveal } from '@devseed-ui/animation';

import MetaTags from './meta-tags';
import PageFooter from './page-footer';
import PageHeader from './page-header';
import SizeAwareElement from './size-aware-element';

const appTitle = process.env.APP_TITLE;
const appDescription = process.env.APP_DESCRIPTION;

const Page = styled.div`
  display: grid;
  grid-template-rows: ${({ hideHeader, hideFooter }) => {
    let header = `minmax(2rem, min-content)`;

    let footer = 'min-content';
    if (hideHeader) {
      header = '';
    }
    if (hideFooter) {
      footer = '';
    }
    return `${header} auto ${footer}`;
  }};

  min-height: 100dvh;
  top: 0;
`;

const PageBody = styled.div`
  flex-grow: 1;
  animation: ${reveal} 0.48s ease 0s 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0;
  margin: 0;
  inset: 0;
`;

function LayoutRoot(props) {
  const { children } = props;
  const { pageTitle, keywords, hideFooter, isMapCentric, location } =
    useContext(LayoutRootContext);

  // Handle cases where the page is updated without changing
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const title = pageTitle ? `${pageTitle} — ` : '';

  return (
    <SizeAwareElement>
      <MetaTags
        title={`${title}${appTitle}`}
        description={appDescription}
        keywords={keywords}
      />
      <Page>
        <PageHeader isMapCentric={isMapCentric} />
        <PageBody>
          <Outlet />
          {children}
        </PageBody>
        {!hideFooter && <PageFooter />}
      </Page>
    </SizeAwareElement>
  );
}

LayoutRoot.propTypes = {
  children: T.node,
};

export default LayoutRoot;

// Context
export const LayoutRootContext = createContext({});

export function LayoutRootContextProvider({ children }) {
  const [layoutProps, setLayoutProps] = useState({});

  const ctx = {
    ...layoutProps,
    setLayoutProps,
  };

  return (
    <LayoutRootContext.Provider value={ctx}>
      {children}
    </LayoutRootContext.Provider>
  );
}

LayoutRootContextProvider.propTypes = {
  children: T.node,
};

export function LayoutProps(props) {
  const { setLayoutProps } = useContext(LayoutRootContext);

  useEffect(() => {
    setLayoutProps(props);
  }, [setLayoutProps, props]);

  return null;
}
