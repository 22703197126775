import React from 'react';
import styled from 'styled-components';
import { media, themeVal, multiply } from '@devseed-ui/theme-provider';

import { Inpage, InpageBody, InpageBodyInner } from '../../styles/inpage';

const FooterSections = styled(InpageBodyInner)`
  display: flex;
  flex-flow: column nowrap;

  span,
  p,
  a {
    font-size: ${multiply(themeVal('type.base.size'), 0.875)};
    color: ${themeVal('color.surface-400a')};
  }
  a,
  a:visited {
    color: ${themeVal('color.surface-400a')};
    text-decoration: underline;
  }
  ${media.smallUp`
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  `}
`;
function PageFooter() {
  return (
    <Inpage as='footer'>
      <InpageBody dark>
        <FooterSections>
          <span>
            Site by{' '}
            <a
              href='https://developmentseed.org'
              target='_blank'
              rel='noreferrer'
            >
              Development Seed
            </a>
          </span>
          <div>
            <p>Urban Blight Tracker</p>
            <p>
              &copy; {new Date().getFullYear()}{' '}
              <a
                href='https://childpovertyactionlab.org'
                target='_blank'
                rel='noreferrer'
              >
                Child Poverty Action Lab
              </a>
            </p>
          </div>
        </FooterSections>
      </InpageBody>
    </Inpage>
  );
}

export default PageFooter;
