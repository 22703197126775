'use strict';
const defaultsDeep = require('lodash.defaultsdeep');
/*
 * App configuration.
 *
 * Uses settings in config/production.js, with any properties set by
 * config/staging.js or config/local.js overriding them depending upon the
 * environment.
 *
 * This file should not be modified.  Instead, modify one of:
 *
 *  - config/production.js
 *      Production settings (base).
 *  - config/staging.js
 *      Overrides to production if ENV is staging.
 *  - config/local.js
 *      Overrides if local.js exists.
 *      This last file is gitignored, so you can safely change it without
 *      polluting the repo.
 */

// Initialize with base config
var config = require('./config/production.js');

// Load environment-specific configs
var envConfig = {
  staging: require('./config/staging.js'),
  testing: require('./config/testing.js'),
  jest: require('./config/jest.js'),
};

if (process.env.NODE_ENV === 'testing') {
  config = defaultsDeep(envConfig.testing || {}, config);
} else if (process.env.NODE_ENV === 'staging') {
  config = defaultsDeep(envConfig.staging, config);
} else if (process.env.NODE_ENV === 'jest') {
  config = defaultsDeep(envConfig.jest, config);
}

// Apply local config
config = defaultsDeep({}, require('./config/local.js') || {}, config);

// The require doesn't play super well with es6 imports. It creates an internal
// 'default' property. Export that.
export default config.default;
