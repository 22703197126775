import React from 'react';
import styled from 'styled-components';
import { Router } from 'react-router';
import history from './history';
import { DevseedUiThemeProvider } from '@devseed-ui/theme-provider';
import theme from './styles/theme';
import { themeVal, glsp } from '@devseed-ui/theme-provider';
import PageFooter from './components/common/page-footer';
import PageHeader from './components/common/page-header';

const PageContent = styled.section`
  min-height: calc(100vh - 15.5rem);
  display: grid;
  place-content: center;
  text-align: center;

  pre {
    font-size: 0.75rem;
    padding: ${glsp()};
    margin: ${glsp(4)} auto 0;
    background: ${themeVal('color.baseAlphaC')};
    white-space: normal;
    max-width: ${themeVal('layout.max')};
  }
`;

export default class ErrorBoundary extends React.Component {
  static getDerivedStateFromError(error) {
    return { error: error };
  }

  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  render() {
    return this.state.error ? (
      <Router history={history}>
        <DevseedUiThemeProvider theme={theme.light}>
          <PageHeader disabled />
          <PageContent>
            <h1>Page Error</h1>
            <p>
              There was an error in loading this page.{' '}
              <span
                style={{ cursor: 'pointer', color: '#0077FF' }}
                onClick={() => {
                  window.location.reload();
                }}
              >
                Reload this page
              </span>{' '}
              or go back to the{' '}
              <span
                style={{ cursor: 'pointer', color: '#0077FF' }}
                onClick={() => {
                  window.location.href = '/';
                }}
              >
                homepage.
              </span>{' '}
            </p>
            <pre>
              <code>
                Error: <span>{this.state.error.message}</span>
              </code>
            </pre>
          </PageContent>
          <PageFooter disabled />
        </DevseedUiThemeProvider>
      </Router>
    ) : (
      // eslint-disable-next-line react/prop-types
      this.props.children
    );
  }
}
