import { createGlobalStyle, css } from 'styled-components';
import { glsp, media, themeVal } from '@devseed-ui/theme-provider';

const customStyles = css`
  .mapboxgl-ctrl-bottom-right .mapboxgl-ctrl {
    margin: 0 ${glsp(0.75)} ${glsp(0.75)} 0;
  }
  .mapboxgl-ctrl-group:not(:empty) {
    box-shadow: none;
  }
  .mapboxgl-ctrl-bottom-left .mapboxgl-ctrl {
    margin: 0 0 42px 12px;
  }
  ${media.smallUp`
    .mapboxgl-ctrl-bottom-right .mapboxgl-ctrl,
    .mapboxgl-ctrl-bottom-left .mapboxgl-ctrl {
      margin: ${glsp(0.75)};
    }
  `}
  .mapboxgl-ctrl-group button {
    height: ${glsp(2)};
    width: ${glsp(2)};
    border: 1px solid rgba(68, 63, 63, 0.16);
  }
  #splash-screen {
    background: rgba(248, 248, 248, 0.84);
  }
  .mapillary-zoom-container {
    bottom: 1.75rem;
    right: 0.75rem;
  }
  .region-popup .mapboxgl-popup-content {
    font-size: 0.75rem;
    line-height: 1rem;
    border-radius: 0.25rem;
    & > div:first-child {
      text-transform: uppercase;
      font-weight: 700;
      color: ${themeVal('color.base-400')};
    }
    & > dl {
      display: grid;
      grid-template-columns: 7rem 1fr;
      grid-gap: ${glsp(0.25)};
      align-items: baseline;

      & > dt {
        line-height: 1rem;
        color: ${themeVal('color.base-400')};
      }
      & > dd {
        line-height: 1rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  header[class^='panel__PanelHeader']:first-of-type
    input#blight-index-switch:checked
    ~ span[class^='switch__FormOptionUi'] {
    background: ${themeVal('color.tertiary')};
  }
`;

const GlobalStyles = createGlobalStyle`
  ${customStyles}
`;

export default GlobalStyles;
